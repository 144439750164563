<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea">
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Disposisi Siap Kirim</b>
            </div>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Datatables
              table-name="document_in"
              :trigger="{
                from: function () {
                  return from;
                },
              }"
              @doDetail="doDetail"
              :action="true"
              :columnFiltering="true"
              action-data="row_number"
              :url="'disposition_already'"
              :dtfields="headers"
            >
              <template slot-scope="props">
                <div
                  class="btn-group btn-sm"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    v-if="!forbidRead"
                    data-action="doDetail"
                    class="btn btn-success btn-sm"
                    title="Detail Surat"
                  >
                    <i class="mdi mdi-magnify"></i>
                  </button>
                  <button
                    type="button"
                    v-if="!forbidUpdate"
                    data-action="doEdit"
                    class="btn btn-warning btn-sm"
                    title="Edit Surat"
                  >
                    <i class="mdi mdi-grease-pencil"></i>
                  </button>
                </div>
              </template>
            </Datatables>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    // let _this = this
    let roles = this.$store.state.profile.user.datarole1;

    return {
      from: roles,
      headers: {
        agenda_number: {
          label: "Nomor Agenda",
          data: "agenda_number",
        },
        nomorsurat: {
          label: "Nomor Surat",
          data: "nomorsurat",
        },
        subject: {
          label: "Perihal",
          data: "subject",
        },
        tanggal_dispo: {
          label: "Tanggal Disposisi Dibuat",
          data: "tanggal_dispo",
          render: function (data, row, d) {
            return this.formatDate(data);
          },
        },
      },
    };
  },
  computed: {
    state() {
      return this.$store.state.dispositionAlreadySend;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return !this.$store.state.profile.permissions.disposition_in.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return !this.$store.state.profile.permissions.disposition_in.read;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return !this.$store.state.profile.permissions.disposition_in.delete;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: true,
      search: this.search,
      items: [],
    };
    this.$store.commit("dispositionAlreadySend/STATE", state);
  },
  methods: {
    changeDt() {
      this.$children[0].$refs.table.reload();
    },
    onSearch(val) {
      this.search = val;
    },
    get(val) {
      this.$store.dispatch(
        "dispositionAlreadySend/getDispositionAlreadySend",
        val
      );
    },
    doDetail(val) {
      this.$store.dispatch("dispositionAlreadySend/onDetail", val);
      // const idsurat = val.id;
      // let route = this.$router.resolve({
      //   path: "/dispositionAlreadySend/detail",
      // });
      // let route1 = `${route.href}/${idsurat}`;
      // window.open(route1, "_blank");
    },
    doEdit(val) {
      this.$store.dispatch("dispositionAlreadySend/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("dispositionAlreadySend/submitDelete", val);
    },
  },
};
</script>
